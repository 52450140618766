import React from 'react';
import { Link } from 'react-router-dom';
import Blogs from './Blogs';

function Sidebar() {
    return (
        <div className="w-full md:w-1/4 p-4 space-y-8">
            <div className="bg-[#f9f9f9] p-6 rounded-md shadow-md">
                <h2 className="text-lg font-serif text-[#222222] mb-4">Follow Me</h2>
                <div className="flex space-x-4 justify-center">
                    <a href="https://www.facebook.com/decode.ip/" className="heading hover:text-blue-500">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://www.instagram.com/decode.ip/" className="heading hover:text-blue-500">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/decode-ip/" className="heading hover:text-blue-500">
                    <i class="fa-brands fa-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className="bg-[#f9f9f9] p-6 rounded-md shadow-md">
                <h2 className="text-lg font-serif text-[#222222] mb-4">Services We Provide</h2>
                <div className="flex flex-col space-y-2">
                    <a
                        href="https://www.decodeip.com/trademarkregistration"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn text-white text-center py-2 rounded-md hover:bg-blue-500 transition-colors duration-300"
                    >
                        Trademark Registration in India
                    </a>
                    <a
                        href="https://www.decodeip.com/trademarkregistrationstartup"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn text-white text-center py-2 rounded-md hover:bg-blue-500 transition-colors duration-300"
                    >
                        Trademark Registration For Start-Ups
                    </a>
                    <a
                        href="https://www.decodeip.com/internationalregistration"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn text-white text-center py-2 rounded-md hover:bg-blue-500 transition-colors duration-300"
                    >
                        International Trademark Registration
                    </a>
                    <a
                        href="https://www.decodeip.com/trademarkregistrationwellknown"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn text-white text-center py-2 rounded-md hover:bg-blue-500 transition-colors duration-300"
                    >
                        Registration Of Well-Known Trademark
                    </a>
                </div>

            </div>
            <div className="bg-[#f9f9f9] p-6 rounded-md shadow-md">
                <h2 className="text-lg font-serif text-[#222222] mb-4">Contact Me</h2>
                <form>
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Your Name"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#51afc4]"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            placeholder="Your Email"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#51afc4]"
                        />
                    </div>
                    <div className="mb-4">
                        <textarea
                            placeholder="Your Message"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#51afc4]"
                            rows="4"
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="w-full btn  text-white p-2 rounded-md hover:bg-blue-500 transition-colors duration-300"
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    );
}

export default function ContentSection({ title, imageUrl, upcontent, dncontent, Date }) {
    return (
        <div className="flex flex-col md:flex-row justify-between items-start mt-7 space-y-6 md:space-y-0 md:space-x-6">
            <div className="flex flex-col justify-center items-center w-full md:w-3/4">
                <span className="text-2xl font-serif text-[#222222] mb-7 transition-transform duration-500 ease-in-out hover:scale-105">
                    {title}
                </span>
                <div className="flex text-center mb-5">
                    <div className="w-28 h-1 border-b-2 line mt-2 rounded-2xl md:mt-1"></div>
                    <span className="-mt-0.5 mx-3 italic text-sm heading">{Date}</span>
                    <div className="w-28 h-1 border-b-2 line mt-2 rounded-2xl md:mt-1"></div>
                </div>
                <div className="sm:mx-36 mt-4 text-lg md:text-sm mb-5 font-serif leading-8 space-y-6 text-center md:text-left">
                    {upcontent.map((paragraph, index) => (
                        <span key={index} className="block transition-opacity duration-500 ease-in-out hover:opacity-90">
                            {paragraph}
                        </span>
                    ))}
                </div>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        className="h-[200px] md:h-[500px] rounded-sm transition-transform duration-500 ease-in-out hover:scale-105 mb-5"
                        alt={title}
                    />
                )}
                <div className="sm:mx-36 mt-7 text-lg md:text-sm  mb-5 font-serif  leading-8 space-y-6 text-center md:text-left">
                    {dncontent.map((paragraph, index) => (
                        <span key={index} className="block transition-opacity duration-500 ease-in-out hover:opacity-90">
                            {paragraph}
                        </span>
                    ))}
                </div>
            </div>

            <Sidebar />

        </div>
    );
}