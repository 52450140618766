import React from 'react';

const Card = ({ image, title, description }) => {
  return (
    <a 
      href="https://www.decodeip.com/" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="w-full md:w-1/2 lg:w-1/4 p-4 block"
    >
      <div className="bg-white rounded-lg shadow-lg h-56 md:h-52  overflow-hidden card-transition">
        <div className="p-6">
          <div className="flex items-center justify-center mb-4">
            <img src={image} alt={title} className="w-12 h-12 object-contain" />
          </div>
          <h3 className="text-xl font-semibold mb-2">{title}</h3>
          <p className="text-gray-700">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default Card;
