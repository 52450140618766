import React from 'react';
import PropTypes from 'prop-types';

const Box = ({ heading, paragraph }) => {
  return (
    <a 
      href="https://www.decodeip.com/" 
      target="_blank" 
      rel="noopener noreferrer" 
      className="block w-[415px] h-[350px] p-[30px] mb-5 bg-sky-400 text-white border rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <h3 className="text-3xl font-bold font-sans mb-4">{heading}</h3>
          <p className="text-lg font-sans">{paragraph}</p>
        </div>
      </div>
    </a>
  );
};

Box.propTypes = {
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default Box;
