import React from 'react';
import IconText from './IconText'; // Adjust the import path as needed

const BenifitsInfo = () => {
  return (
    <div className="flex flex-col sm:flex-row flex-wrap justify-around p-4 gap-6">
      <IconText icon="https://www.decodeip.com/assets/img/arrow/Group%208.png" isFirst={true} />
      <IconText icon="https://www.decodeip.com/assets/img/arrow/Group%209.png"  />
      <IconText icon="https://www.decodeip.com/assets/img/arrow/Group%2010.png"  />
      <IconText icon="https://www.decodeip.com/assets/img/arrow/Group%2011.png" />
    </div>
  );
};

export default BenifitsInfo;
