import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsThree() {
  const blogContent1 = [
    "In the bustling world of business, establishing a unique brand identity is crucial for success. One of the most effective ways to protect this identity is through trademark registration. However, before diving into the process, it’s vital to conduct a comprehensive preliminary search. This step, though often overlooked, can save businesses from significant legal and financial troubles down the road.",
    "A trademark is a symbol, word, phrase, design, or combination that identifies and distinguishes the source of goods or services of one party from those of others. Registering a trademark grants the owner exclusive rights to use the mark in connection with the specified goods or services. This not only protects the brand from infringement but also enhances its market value.",
    
  ];
  const blogContent2=[
    "With the advent of technology, online trademark registration has become increasingly accessible. Platforms and governmental websites provide straightforward processes for filing applications. However, the ease of registration should not overshadow the importance of due diligence before submitting a trademark application.",
    "Conducting a preliminary search helps identify existing trademarks that are similar to your proposed mark. If your mark is too similar to an existing one, it could lead to infringement claims. This can result in costly legal battles and the possibility of being forced to rebrand, which can be devastating for a business’s reputation and finances."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://storage.googleapis.com/profit-prod/wp-content/uploads/2020/10/c8b481f4-effective-performance-reviews.jpg"
      title="Importance of Comprehensive  Search Before Trademark Registration"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" The Importance of Trademark Registration"
      imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiChtQ3R4p5IxJE1oUaPIcBBUOLh68rIKEM62NkUfpaUiyLGO1_pfHy2WDUZxm4c7PlI8&usqp=CAU"
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
