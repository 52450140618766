import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsSixth() {
  const blogContent1 = [
    "In the fast-paced world of startups, protecting intellectual property is crucial for maintaining a competitive edge. Among the various forms of intellectual property, trademarks play a vital role in establishing a unique identity in the marketplace. A trademark can be a word, phrase, symbol, design, or a combination thereof that identifies and distinguishes the source of goods or services of one party from those of others. For startups, registering a trademark is not just a legal formality but a strategic move that can offer numerous benefits.",
    "Before applying for a trademark, it is essential to conduct a comprehensive search to ensure that your desired mark is not already in use. This involves checking the databases of the trademark office in your country, such as the United States Patent and Trademark Office (USPTO) in the United States. There are also several online tools and professional services that can assist with this process. A thorough search helps to avoid potential legal conflicts and delays in the registration process.",
    
  ];
  const blogContent2=[
    "First we File the Application, The application can be filed online through the trademark office's website. For instance, the USPTO provides an online filing system called the Trademark Electronic Application System (TEAS). The filing fee varies depending on the type of application and the number of classes of goods or services. Afther that Examination by the Trademark Office is done , After submission, the application undergoes examination by a trademark examiner. The examiner reviews the application to ensure compliance with the legal requirements and checks for any conflicts with existing trademarks. If the examiner finds any issues, they will issue an Office Action, detailing the reasons for refusal or requesting additional information. The applicant must respond to the Office Action within a specified period. ",
    "Publication for Opposition, If the examiner approves the application, the trademark is published in the official gazette of the trademark office for opposition. This publication allows third parties to challenge the registration if they believe it will infringe on their existing trademarks. The opposition period usually lasts for 30 days. In the Registration part , If no opposition is filed, or if the opposition is resolved in favor of the applicant, the trademark is registered. The trademark office will issue a registration certificate, officially recognizing the mark as protected under trademark law. In Maintenance and Renewal , Trademark registration is not indefinite and requires maintenance. In the U.S., for example, the first maintenance document must be filed between the fifth and sixth year after registration, and renewals are required every ten years. Regular monitoring and timely renewals are essential to maintain the protection of the trademark."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://decodeip.s3.amazonaws.com/images/1721740428941-Trademark%20Registration%20for%20Startups.jpg"
      title="Simplified Process of Trademark Registration"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" The advent of AI in trademark searches"
      imageUrl="https://www.catandpillar.com/blog/wp-content/uploads/2022/04/Increase-in-Trademark-Registration-Renewal-Fees-In-Singapore.jpeg"
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
