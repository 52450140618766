import React from 'react'

export default function Story() {
  return (
    <>
    <div CLASS="mt-14 ml-14 mb-14 ">
      <div><h2 class="text-gray-600 text-5xl font-bold">OUR STORY</h2></div>
      <div class=" line w-14 h-1 border-b-4 mt-2 rounded-2xl "></div>
     


    </div>
     <div class="mt-4 ml-3 md:ml-14 flex flex-col ">
     <p class="text-gray-600 text-lg mb-4">DecodeIP is a leading technology based online trademark platform to provide all services related to trademark protection, prosecution and enforcement to various industries within India and globally. It is first portal of its kind which provides one stop solution for all trademark services with ease of technology at an affordable cost. </p>
     <p class="text-gray-600 text-lg">The DecodeIP is a platform conceptualized & designed by Intellectual Property Strategists with more than decade of experience of legal counselling and representations in intellectual property litigation & advisory for leading multinational and leading companies around the globe. The conceptualization of DecodeIP started with a vision to create an ecosystem to facilitate seamless process for protection, registration, enforcement, maintenance and monetization of trademarks in a most possible transparent manner at an affordable cost. </p>
   </div>
   </>
  )
}
