import React from 'react';
import Box from './Box';


const Choose = () => {
  return (
    <>
    <div CLASS="mt-14 ml-14 mb-14 ">
    <div><h2 class="text-gray-600 text-4xl font-bold">WHY CHOOSE US</h2></div>
    <div class=" line w-14 h-1 border-b-4 mt-2 rounded-2xl "></div>
    
    </div>
    <div className="flex flex-wrap justify-center gap-6 mt-4">
    <Box
      heading="Our Ideology"
      paragraph="DecodeIP helps individuals, startups, small businesses, law firms, and corporations protect and build their trademark portfolios affordably through technology. We believe every brand has a unique story, and our mission is to safeguard these stories with comprehensive legal protection. By ensuring your trademark remains truly yours, we help turn your brand into a high-value asset."
    />
    <Box
      heading="Our Mission"
      paragraph="A trademark is a unique, transferable asset offering perpetual monopolistic rights, which can be passed down as a legacy. At DecodeIP, our mission is to raise awareness about the importance of trademarks and create a seamless ecosystem for their protection, registration, enforcement, maintenance, and monetization."
    />
    <Box
      heading="Our Impeccable Team"
      paragraph="Our team of dedicated trademark professionals combines legal expertise and technical skill to guide brand owners through trademark registration and portfolio building. They undergo rigorous training, designed by DecodeIP’s founders, to provide tailored services that meet each client's unique needs."
    />
  </div>
  </>
  );
};

export default Choose;
