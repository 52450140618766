import React from 'react'
import BenifitsInfo from './BenifitsInfo'

export default function Tools() {
  return (
    <>
    <div CLASS="mt-14 ml-10 ">
      <div><h2 class="text-gray-600 text-4xl">ONLINE TOOLS</h2></div>
      <div class=" line w-14 h-1 border-b-4 mt-2 rounded-2xl "></div>
      <p class="text-gray-600 text-lg mt-4 ml-3">We have online tools and resources to support trademark owners at each stage of trademark registration lifecycle.</p>
     
    </div>
     <BenifitsInfo />
     </>
  )
}
