import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const IconText = ({ icon, text, isFirst }) => {
  return (
    <motion.div
      className={`flex items-center justify-center mt-8 ${!isFirst ? 'ml-1' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <a href="https://www.decodeip.com/" target="_blank" rel="noopener noreferrer" className="relative group">
        <div className="flex flex-col items-center">
          <motion.img
            src={icon}
            alt={text}
            className="w-24 mb-2 cursor-pointer"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          />
          <motion.p
            className="text-lg font-semibold text-gray-700"
            whileHover={{ color: '#51afc4' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {text}
          </motion.p>
        </div>
        
        {!isFirst && (
          <motion.div
            className="absolute top-0 h-full border-gray-300 group-hover:border-gray-400"
            initial={{ height: 0 }}
            animate={{ height: '100%' }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
          />
        )}
      </a>
    </motion.div>
  );
};

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
};

IconText.defaultProps = {
  isFirst: false,
};

export default IconText;
