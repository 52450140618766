import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsTwo() {
  const blogContent1 = [
    "In the dynamic world of business, change is inevitable. Companies develop ownership transitions and new partnerships form. One significant aspect of this evolution is a change in proprietorship, which can have profound implications for various aspects of the business, including trademark registration.",
    "Let's explore what a change in proprietorship means for your trademark registration, why it matters, and how you can navigate the process efficiently. Additionally, we'll touch upon the importance of online trademark registration in today's digital age.",
    
  ];
  const blogContent2=[
    "Proprietorship refers to the ownership of a business. In a sole proprietorship, a single individual owns and runs the business. In partnerships, multiple individuals share ownership, while in corporations and limited liability companies (LLCs), ownership is divided among shareholders or members.",
    "A trademark is a recognizable sign, design, or expression that identifies products or services of a particular source from those of others. Trademarks can be names, logos, slogans, or even sounds and colors. They serve as a valuable asset, representing the brand's identity and reputation in the market."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://decodeip.s3.amazonaws.com/images/1721995126464-Change%20in%20Proprietorship.jpg"
      title="Understanding Proprietorship and Trademarks"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" The Importance of Trademark Registration"
      imageUrl="https://www.corporatelivewire.com/images/blogs/1020/1985896538.jpg"
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
