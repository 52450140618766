import React from 'react';

const HeroSection = ({ imageUrl, title, subtitle }) => {
    return (
        <div className="relative w-full h-[50vh] mt-3">
            <img
                className="absolute  md:block inset-0 w-full h-full object-cover object-center"
                src={imageUrl}
                alt={title}
            />
          
            <div className="absolute inset-0 bg-black bg-opacity-40 bg-fixed flex flex-col justify-center items-center">
                
                <div className="px-4 py-2 rounded">
                    <h2 className="text-2xl md:text-4xl  text-center font-bold text-white">
                        {title}
                    </h2>
                    <p className="text-lg md:text-3xl text-center font-semibold text-white">
                        {subtitle}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
