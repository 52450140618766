import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsOne() {
  const blogContent1 = [
    "A trademark is a symbol, word, phrase, design, or combination that identifies and distinguishes the source of goods or services of one party from those of others. Registering a trademark grants the owner exclusive rights to use the mark in connection with the specified goods or services. This not only protects the brand from infringement but also enhances its market value.",
    "With the advent of technology, online trademark registration has become increasingly accessible. Platforms and governmental websites provide straightforward processes for filing applications. However, the ease of registration should not overshadow the importance of due diligence before submitting a trademark application",
    
  ];
  const blogContent2=[
    "Online trademark registration platforms have revolutionized the registration process, making it more accessible to businesses of all sizes. These platforms offer streamlined processes, cost-effective solutions, and user-friendly interfaces. However, they also emphasize the importance of conducting a preliminary search.",
    "Many online trademark registration services include preliminary search tools as part of their offering. These tools can help identify potential conflicts early on, saving applicants time and money. While these tools are valuable, they should be used in conjunction with manual searches and professional legal advice to ensure thoroughness."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://decodeip.s3.amazonaws.com/images/1721996257191-Comprehensive%20Preliminary.jpg"
      title="Understanding Trademark Registration"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" Trademark Registration"
      imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxmPuXpo8f4V5fWMMcdOpMxC5XaSJFJ3LBCw&s"
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
