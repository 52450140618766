import React, { useState } from 'react';
import axios from 'axios';

export default function Form() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    if (isSubmitting) return; 

    setIsSubmitting(true); 
    try {
      const response = await axios.post('https://api.decodeip.com/contacts', {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        subject: 'Contact Form Submission'
      });

      console.log('Response:', response.data);
      
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error);
     
    } finally {
      setIsSubmitting(false); 
    }
  };

  return (
    <div className="bg-blue-50">
      <section className="p-10 mt-2">
        <div className="flex-col flex justify-center items-center">
          <p className="text-gray-600 cursor-pointer text-4xl md:text-4xl text-left">Contact Us</p>
          <div className="w-32 h-1 border-b-4 border-blue-400 rounded-2xl mt-4 mb-5"></div>
          <p className="text-gray-600 cursor-pointer text-lg">
            Protect your brand and boost your business’s credibility! Contact us today for a free consultation on trademark registration and secure your unique identity.
          </p>
        </div>
        <div className="bg-white p-10 rounded-lg mt-10 shadow-lg max-w-lg mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                <input
                  className="border w-full mt-4 text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-2">
                <input
                  className="border w-full mt-4 text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                className="border w-full mt-4 text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-4">
              <textarea
                className="border w-full mt-4 text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600"
                name="message"
                rows="4"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div className="text-center">
              <button
                className={`btn text-white font-bold py-2 px-4 rounded-full ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'hover:bg-blue-400'}`}
                type="submit"
                disabled={isSubmitting} 
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
