import React from 'react';
import { Link } from 'react-router-dom';

function ServiceCard({ title, description, imageUrl, link }) {
    const cardContent = (
        <div className="relative bg-white shadow-lg rounded-lg sm:text-center  overflow-hidden transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <img
                src={imageUrl}
                alt={title}
                className="w-full h-48 object-cover"
            />
            <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{title}</h3>
                <p className="text-gray-600 mb-4">{description}</p>
                <button className="text-blue-400 py-2 px-4 rounded-full font-semibold hover:bg-blue-500 hover:text-white  transition-colors duration-300">
                    Learn More <i className="fa-solid fa-arrow-right ml-2"></i>
                </button>
            </div>
            <div className="absolute top-0 left-0 h-1 w-0 bg-green-700 transition-all duration-500 hover:w-full"></div>
        </div>
    );

    return link ? (
        <Link to={link}>
            {cardContent}
        </Link>
    ) : (
        <div>
            {cardContent}
        </div>
    );
}

export default ServiceCard;
