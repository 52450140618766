import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
    return (
        <div>
            <footer class="bg-gray-800 text-white py-8">
                <div class="container mx-auto px-6 md:px-12">
                    <div class="flex flex-wrap md:flex-nowrap justify-between">

                        <div class="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-left">
                            <img src="https://www.decodeip.com/assets/img/3d-decode-ip-logo.png" alt="Logo" class="h-36 w-36 mb-4"></img>
                            <p class="text-gray-400 flex flex-col align-left  justify-center">DecodeIP offers comprehensive trademark registration services to protect and enhance your brand’s identity. Our team ensures a seamless and efficient registration process to safeguard your intellectual property.</p>
                        </div>


                        <div class="w-full md:w-1/3 mb-8  md:mb-0 flex flex-col pt-8 items-left">
                            <h3 class="text-lg font-semibold   mb-4">Contact Us</h3>
                            <div class="flex  flex-col ">
                                <div class="flex">
                                    <div>
                                        <i class="fa-brands fa-whatsapp text-blue-400 mr-2"></i>
                                    </div>
                                    <div>
                                    <span>+918700534740</span>
                                    </div>
                                </div>

                                <div class="flex">
                                    <div>
                                    <i class="fa-solid fa-envelope text-blue-400 mr-2"></i>
                                    </div>
                                    <div>
                                    <span>support@decodeip.com</span>
                                    </div>
                                </div>
                              
                            </div>

                        </div>


                        <div class="w-full md:w-1/3 text-left flex flex-col pt-8 items-left">
                            <h3 class="text-lg font-semibold  mb-4">Quick Links</h3>
                            <span className="hover:text-blue-500 transition duration-300">
                                <Link to="/">Home</Link>
                            </span>
                            <span className="hover:text-blue-500 transition duration-300">
                                <Link to="/blogs">Blogs</Link>
                            </span>
                            <span className="hover:text-blue-500 transition duration-300">
                                <Link to="/contact-us">Contact</Link>
                            </span>
                        </div>
                    </div>
                    <div class="mt-8 border-t border-gray-700 pt-4 text-center">
                        <p class="text-gray-400">&copy; 2024 DecodeIP. All rights reserved.</p>
                        <div class="mt-4">
                            <a href="#" class="text-blue-400 mx-2 hover:text-blue-600"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" class="text-blue-400 mx-2 hover:text-blue-600"><i class="fab fa-twitter"></i></a>
                            <a href="#" class="text-blue-400 mx-2 hover:text-blue-600"><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
