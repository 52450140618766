import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Form from './Component/Form';
import Navbar from './Component/Navbar';
import BlogPage from './Component/BlogPage';
import BlogNewsOne from './Component/BlogNewsOne';
import BlogNewsTwo from './Component/BlogNewsTwo';
import BlogNewsThree from './Component/BlogNewsThree';
import BlogNewsFour from './Component/BlogNewsFour';
import BlogNewsFifth from './Component/BlogNewsFifth';
import BlogNewsSixth from './Component/BlogNewsSixth';
import DecodeHome from './Component/DecodeHome';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="pt-24"> {/* Adjust the padding based on your navbar height */}
        <Routes>
          <Route path="/" element={<DecodeHome />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/contact-us" element={<Form />} />
          <Route path="/BlogNewsOne" element={<BlogNewsOne />} />
          <Route path="/BlogNewsTwo" element={<BlogNewsTwo />} />
          <Route path="/BlogNewsThree" element={<BlogNewsThree />} />
          <Route path="/BlogNewsFour" element={<BlogNewsFour />} />
          <Route path="/BlogNewsFifth" element={<BlogNewsFifth />} />
          <Route path="/BlogNewsSixth" element={<BlogNewsSixth />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
