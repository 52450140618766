import React from 'react'
import Cardinfo from './Cardinfo'

export default function Process() {
  return (
    <>
      <div CLASS="mt-14 ml-14 ">
        <div><h2 class="text-gray-600 text-4xl">PROCESS</h2></div>
        <div class=" line w-14 h-1 border-b-4 mt-2 rounded-2xl "></div>


      </div>
      <Cardinfo />
    </>
  )
}
