import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="fixed w-full top-0 z-50 bg-white ">
      <div className="container mx-auto flex justify-between items-center h-24 md:h-24 px-4 md:px-6">
        
        <div className="flex items-center">
          <img
            src="https://www.decodeip.com/assets/img/3d-decode-ip-logo.png"
            alt="Logo"
            className="h-24 md:h-24"
          />
        </div>

       
        <div className="hidden md:flex font-semibold space-x-6">
          <Link
            to="/"
            className="hover:text-blue-500 transition duration-300"
          >
            Home
          </Link>
          <Link
            to="/blogs"
            className="hover:text-blue-500 transition duration-300"
          >
            Blogs
          </Link>
          <Link
            to="/contact-us"
            className="hover:text-blue-500 transition duration-300"
          >
            Contact
          </Link>
        </div>

      
        <div className="flex items-center space-x-4">
          <i className="fa-solid fa-phone hidden md:block text-black"></i>
          <span className="hidden md:block text-black">
            +918700534740
          </span>
     
          <a
            href="https://www.decodeip.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded transition duration-300"
          >
            Learn More
          </a>
        </div>

        
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-2xl text-black focus:outline-none"
            aria-label="Toggle menu"
          >
            <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>
        </div>
      </div>

     
      {isOpen && (
        <div className="md:hidden bg-white shadow-md py-6 px-6 space-y-4">
          <Link
            to="/"
            className="block text-black hover:text-blue-500 transition duration-300"
            onClick={toggleMenu}
          >
            Home
          </Link>
          <Link
            to="/blogs"
            className="block text-black hover:text-blue-500 transition duration-300"
            onClick={toggleMenu}
          >
            Blogs
          </Link>
          <Link
            to="/contact-us"
            className="block text-black hover:text-blue-500 transition duration-300"
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
}
