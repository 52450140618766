import React from 'react';
import Card from './Card';

const Cardinfo = () => {
  const cardData = [
    {
      image: 'checklist.png', 
      title: 'Filing of Trademark Application',
      description:
        'A trademark application is to be filed with the Registry along with the prescribed fees.',
    },
    {
        image: 'audit.png', 
        title: 'Examination',
        description:
          'If approved, the application is accepted by the Registrar and published in the Trade Marks Journal. ',
      },
      {
        image: 'marketing.png', 
        title: 'Advertisement',
        description:
          'If approved, the application is accepted by the Registrar and published in the Trade Marks Journal. ',
      },
      {
        image: 'award.png', 
        title: 'Registration',
        description:
          'The trademark application typically takes 5 to 6 months to process and register if there are no objections..',
      },
   
  ];

  return (
    <div className="flex flex-wrap justify-center  ">
      {cardData.map((card, index) => (
        <Card key={index} image={card.image} title={card.title} description={card.description} />
      ))}
    </div>
  );
};

export default Cardinfo;
