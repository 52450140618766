import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsFour() {
  const blogContent1 = [
    "Cancellation refers to the process of removing a trademark from the register. This can occur for various reasons, such as non-use, incorrect registration, or the trademark becoming misleading. Rectification, on the other hand, involves correcting an error or updating information in the trademark register. This could include changes in the proprietor's name, and address, or correcting any clerical errors made during registration.",
    " One of the most common grounds for cancellation is non-use. If a trademark has not been used for a continuous period of five years from the date of registration, it can be subject to cancellation. The rationale is to ensure that the trademark register reflects only those marks actively used in commerce.",
    
  ];
  const blogContent2=[
    "Navigating the complexities of cancellation and rectification proceedings in trademark law requires a thorough understanding of the grounds, processes, and best practices. Regular audits, timely use of trademarks, vigilant monitoring of competitors, and seeking legal advice are essential steps in maintaining a robust trademark portfolio. Furthermore, leveraging the benefits of online trademark registration can streamline the process and reduce the risk of errors.",
    "Conducting a preliminary search helps identify existing trademarks that are similar to your proposed mark. If your mark is too similar to an existing one, it could lead to infringement claims. This can result in costly legal battles and the possibility of being forced to rebrand, which can be devastating for a business’s reputation and finances."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://storage.googleapis.com/profit-prod/wp-content/uploads/2020/10/c8b481f4-effective-performance-reviews.jpg"
      title="Understanding Cancellation and Rectification"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" Grounds for Cancellation"
      imageUrl="https://media.licdn.com/dms/image/C5612AQFquDuT34ebUw/article-cover_image-shrink_600_2000/0/1625477398159?e=2147483647&v=beta&t=KKE6FWv_U5PM477PdG0Pv1DldYSD1TSh4TJ7XmowxGY"
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
