import React from 'react';
import Tools from './Tools';
import Fixedimg from './Fixedimg';
import Process from './Process';
import Story from './Story';

import Choose from './Choose';
import Form from './Form';
import Footer from './Footer';
import Contact from './Contact';

export default function DecodeHome() {
    return (
        <div class="bg-gray-50">
            <div className="relative video-container">
                <video
                    className="w-full h-[400px] md:h-[700px]  object-cover object-bottom"
                    src="https://www.decodeip.com/assets/img/BG/1.mp4"
                    autoPlay
                    muted
                    loop
                ></video>
                <div className="absolute top-2/3 text-center text-lg leading-7 md:leading-snug  md:text-[40px]  font-semibold font-sans  text-white ">
                    The Most Trusted Platform To Register Your Trademark And Build <br class="hidden"></br>  <br class="hidden "></br> A Trademark Portfolio With Ease Of Technology.

                </div>

            </div>
            <Tools />
            <Process />
            <Story/>
            <Choose/>
            <Fixedimg />
            <Form/>
           <Footer/>
        </div>  
            );
}
