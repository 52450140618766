import React from 'react';
import { motion } from 'framer-motion';

export default function Fixedimg() {
  return (
    <div className="relative mt-3" style={{ height: '45vh', width: '100%' }}>
      <div
        className="bg-fixed"
        style={{
          backgroundImage: `linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)), url(https://www.decodeip.com/_next/static/media/newBg2.45828ac2.jpeg)`,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>

      <div className="absolute inset-0 flex justify-center items-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          className="text-white text-center p-4"
        >
          <h2 className="text-2xl md:text-3xl lg:text-2xl font-semibold">
            Every trademark has a story to tell and DecodeIP assists in providing complete legal protection to your <br></br>trademark so that your story remains truly yours.
          </h2>
        </motion.div>
      </div>
    </div>
  );
}
