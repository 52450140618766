import React from 'react'
import HeroSection from './HeroSection';
import ContentSection from './ContentSection';
// import Testimonials from './Testimonials';
import Footer from './Footer';
// import Contact from './Contact';
import Blogs from './Blogs';
export default function BlogNewsFifth() {
  const blogContent1 = [
    "In the dynamic world of intellectual property, trademarks play a pivotal role in distinguishing products and services. A robust trademark not only builds brand identity but also offers legal protection against infringement. However, the process of conducting trademark searches has traditionally been a laborious and intricate task, often fraught with human error.",
    "Trademark searches involve examining existing trademarks to ensure a new trademark does not infringe on existing rights. This process requires sifting through vast databases of registered trademarks, common-law trademarks, and unregistered marks. Traditionally, trademark searches have been conducted manually by trademark attorneys and professionals who analyze linguistic similarities, visual resemblances, and conceptual likenesses. This manual process is time-consuming, prone to human error, and often costly.",
    
  ];
  const blogContent2=[
    "AI's integration into trademark searches marks a significant paradigm shift. AI systems, powered by machine learning algorithms and natural language processing (NLP), can analyze vast amounts of data at unprecedented speeds. These systems can recognize patterns, understand context, and provide insights that far surpass human capabilities. Here’s how AI is transforming the trademark search process.",
    "AI-driven trademark search tools can process and analyze extensive trademark databases in a fraction of the time it takes a human. By automating the initial search process, AI can swiftly identify potential conflicts and similarities, significantly reducing the time required to conduct comprehensive searches. This increased speed allows businesses to expedite their trademark registration processes, giving them a competitive edge in fast-paced markets."

  ]
  return (
    <div>
       <div>
    <HeroSection
      imageUrl="https://decodeip.s3.amazonaws.com/images/1721902992976-How%20AI%20will%20revolutionize%20trademark%20searches.jpg"
      title="Traditional Trademark Search Landscape"
      subtitle=" All about our company"
    />
    <ContentSection
      title=" The advent of AI in trademark searches"
      imageUrl="https://d2qu5xmcgmzxnb.cloudfront.net/ewogICAgICAgICAgICAgICAgICAgICAgICAiYnVja2V0IjogImZpbGVzLmxici5jbG91ZCIsCiAgICAgICAgICAgICAgICAgICAgICAgICJrZXkiOiAicHVibGljL21lZGlhL2ltYWdlcy9haS5qcGciLAogICAgICAgICAgICAgICAgICAgICAgICAiZWRpdHMiOiB7CiAgICAgICAgICAgICAgICAgICAgICAgICAgInJlc2l6ZSI6IHsKICAgICAgICAgICAgICAgICAgICAgICAgICAgICJ3aWR0aCI6IDk0NSwKICAgICAgICAgICAgICAgICAgICAgICAgICAgICJoZWlnaHQiOiA1MjYsCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAiZml0IjogImNvdmVyIgogICAgICAgICAgICAgICAgICAgICAgICAgIH0KICAgICAgICAgICAgICAgICAgICAgICAgfQogICAgICAgICAgICAgICAgICAgIH0="
      upcontent={blogContent1}
      dncontent={blogContent2}
      Date={"10 May 2023"}
    />
  
  <Blogs/>
  
    <Footer/>
  </div>
    </div>
  )
}
