import React from 'react'
import HeroSection from './HeroSection';
import Footer from './Footer'
import ServiceCard from './ServiceCard';
import { Link } from 'react-router-dom';
export default function BlogPage() {
    const services = [
        {
            title: 'Crafting Effective Responses to Office Actions in Trademark Registration',
            // description: 'Keeping your home or business clean from grime and/or buildup can be a challenge. Whether you’re cleaning the whole house or just a patio, tackling these tasks may be best left to a professional.',
            imageUrl: 'https://decodeip.s3.amazonaws.com/images/1721996257191-Comprehensive%20Preliminary.jpg',
            link:"/BlogNewsOne"
        },
        {
            title: 'Change in Proprietorship: What It Means for Your Trademark Registration',
            // description: 'You will be an integral part of The Kleaner ability to maintain the high customer service and presentation standards for which it is known, performing a wide-ranging of functions from fitting garments to sewing machine maintenance.',
            imageUrl: 'https://decodeip.s3.amazonaws.com/images/1721995126464-Change%20in%20Proprietorship.jpg',
            link:"/BlogNewsTwo"
        },
        {
            title: 'The Importance of Comprehensive  Search Before Trademark Registration',
            // description: 'The Alaskan Moose  is a WP theme built for industrial cleaning business but can be used for any type of company that provide cleaning services. Whether you’re a novice or experienced with WordPress this theme will suit your needs',
            imageUrl: 'https://storage.googleapis.com/profit-prod/wp-content/uploads/2020/10/c8b481f4-effective-performance-reviews.jpg',
            link:"/BlogNewsThree"
        },
        {
            title: 'A Guide to Cancellation/Rectification Proceedings in Trademark Law',
            // description: 'As an employer there are many things that your employees expect when they show up to work. Things like to be paid a fair and reasonable wage, to be treated kindly and with respect are just a few.',
            imageUrl: 'https://decodeip.s3.amazonaws.com/images/1721903088680-CancellationRectification%20Proceedings%20in%20Trademark%20Law.jpg',
            link:"/BlogNewsFour"
        },
        {
            title: 'How AI will revolutionize trademark searches and the advent of AI',
            // description: 'Yesterday we were awarded with the ‘Best Cleaning Company’ award. These award of excellence were awarded for projects we designed and installed. This awards reflect our commitment to creative design,',
            imageUrl: 'https://decodeip.s3.amazonaws.com/images/1721902992976-How%20AI%20will%20revolutionize%20trademark%20searches.jpg',
            link:"/BlogNewsFifth"
        },
        {
            title: 'Trademark Registration for Startups: Simplified Process and Benefits',
            // description: 'Rotaries are exclusively used for cleaning and polishing hard floors. They are a versatile floorcare solution that offer outstanding cleaning results and crucially provide high productivity.',
            imageUrl: 'https://decodeip.s3.amazonaws.com/images/1721740428941-Trademark%20Registration%20for%20Startups.jpg',
            link:"/BlogNewsSixth"
        }
    ];
  return (
    
    <div class="">
        <HeroSection
                imageUrl="https://static.vecteezy.com/system/resources/previews/039/349/586/non_2x/a-crowd-of-different-lawyers-or-business-people-on-the-steps-of-a-corporate-building-or-courthouse-variety-communication-and-corporate-culture-and-service-concept-flat-illustration-vector.jpg"
                title="Blogs "
                subtitle=" All about our company"
            />
             <div className="mx-4 sm:mx-8 md:mx-16 lg:mx-[189px] pt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-6 gap-8">
             {services.map((service, index) => (
                    service.link ? (
                        <Link to={service.link} key={index}>
                            <ServiceCard
                                title={service.title}
                                description={service.description}
                                imageUrl={service.imageUrl}
                            />
                        </Link>
                    ) : (
                        <ServiceCard
                            key={index}
                            title={service.title}
                            description={service.description}
                            imageUrl={service.imageUrl}
                        />
                    )
                ))}
            </div>
            <Footer/>
    </div>
  )
}
